@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f3f4f6;/*#fafafa*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Ajoutez ces styles dans votre fichier CSS global ou là où vous importez les fichiers slick-carousel */
.slick-dots li button {
  width: 20px; /* Ajustez la largeur du dot */
  height: 20px; /* Ajustez la hauteur du dot */
  margin: 0 5px; /* Ajustez l'espacement entre les dots */
  border-radius: 50%; /* Pour rendre les coins arrondis */
  background-color: black !important; /* Couleur des dots (blanc) */
  border: none; /* Retirez la bordure si vous ne voulez pas */
  outline: none; /* Retirez la bordure si vous ne voulez pas */
  cursor: pointer;
  content: '';
}



/* Style de la galerie GRID */
.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  grid-gap: 10px;
}

/* Style des éléments de la galerie */
.gallery-item {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Style des images dans la galerie */
.gallery-item img {
  max-width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

/* Media query pour les écrans plus petits */
@media (max-width: 768px) {
  .gallery {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}


/* Ajoutez ces styles dans votre fichier CSS global ou là où vous importez les fichiers slick-carousel */
.slick-prev, .slick-next {
  font-size: 24px;
  color: white;
  background-color: #2196F3; /* Couleur de fond des flèches */
  width: 40px; /* Largeur des flèches */
  height: 40px; /* Hauteur des flèches */
  border-radius: 50%; /* Pour rendre les coins arrondis */
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
}

.slick-prev {
  left: 10px; /* Ajustez la position de la flèche précédente */
}

.slick-next {
  right: 10px; /* Ajustez la position de la flèche suivante */
}

.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 103px !important;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev {
  left: 25px !important;
}


.slick-prev:before, .slick-next:before {
  font-family: 'slick';
  font-size: 50px !important;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;}

  .slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 50px !important;
    line-height: 1;
    opacity: .75;
    color: black !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}



.slide-container {
  position: relative;
}

.click-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px;
  background-color: #fddd01;/*navy*/
  border-color: #eebd29;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.45) !important;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}




